<template>
<div class="wrapper" v-loading.fullscreen.lock="loading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="wp-body">
        <div> 
            <div class="top-header">
                <div class="flex items-center gap-3">
                    <button class="bg-white p-1 w-8 h-8 rounded-xl" @click="$router.go(-1)">
                        <i class="el-icon-arrow-left"></i>
                    </button>
                    <h1>{{$t('railway_facility_add')}}</h1>
                </div>
            </div>
            
            <div class="general-info">
                <div class="general-info-title">
                    {{$t('transport_general_info')}}
                </div>
                <hr>
                <el-collapse v-model="activeNames">
                    <el-form :model="form" :rules="formrules" ref="form1" class="Form">
                        <div class="input-section">
                            <el-row :gutter="30">
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{$t('railway_line')}} </p>
                                        <el-form-item prop="railway_line">
                                            <el-select @change="railway_line" :disabled="disabled_input" v-model="form.railway_line" class="w-full" clearable allow-create filterable :placeholder="$t('railway_line')">
                                                <el-option v-for="(item, i) in dictionary.railway_line" :key="i" :label="item.name" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <div class="railway_line_option">
                                        {{railway_line_option}}
                                    </div>
                                    <label class="item">
                                        <p> {{$t('railway_facility_type')}} </p>
                                        <el-form-item prop="facility_type">
                                            <el-select :disabled="disabled_input" v-model="form.facility_type" class="w-full" clearable filterable allow-create :placeholder="$t('railway_facility_type')">
                                                <el-option v-for="(item, i) in dictionary.facility_type" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>

                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_facility_year')}} </p>
                                        <el-form-item prop="construction_year">
                                            <el-date-picker :picker-options="pickerOptions"  name="construction_year" :disabled="disabled_input" :placeholder="$t('railway_facility_year')" style="width: 100%" v-model="construction_year_input" @change="construction" type="year" value-format="yyyy">
                                            </el-date-picker>
                                        </el-form-item>
                                    </label>
                                </el-col>
                                <el-col :md="8">
                                    <div class="obstacle">
                                        <div class="obstacle-img-input">
                                            <div class="obstacle-img">
                                                <img src="../../assets/img/icons/obstacle.svg" alt="">
                                            </div>
                                            <div class="obstacle-input">
                                                <label class="item">
                                                    <p> {{$t('railway_facility_transition_classification')}}</p>
                                                    <el-form-item prop="transition_classification">
                                                        <el-select :disabled="disabled_input" v-model="form.transition_classification" class="w-full" clearable :placeholder="$t('railway_facility_transition_classification')">
                                                            <el-option v-for="(item,i) in dictionary.transition_classification" :key="i" :label="item.name_cyr" :value="item.id">
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </label> 
                                                <label class="item">
                                                    <p> {{$t('railway_facility_name_barrier')}} </p>
                                                    <el-form-item prop="obstacle_name">
                                                      <el-select :disabled="disabled_input" v-model="form.obstacle_name" class="w-full" clearable :placeholder="$t('railway_facility_name_barrier')">
                                                        <el-option v-for="(item, i) in dictionary.obstacle_name" :key="i" :label="item.name_cyr" :value="item.id">
                                                        </el-option>
                                                      </el-select>
                                                    </el-form-item>
                                                </label>
                                                <label class="item">
                                                    <p> {{$t('railway_facility_type_barrier')}} </p>
                                                  <el-form-item prop="number_of_path">
                                                    <el-select :disabled="disabled_input" v-model="form.number_of_path" class="w-full" clearable :placeholder="$t('type_water_body')">
                                                      <el-option v-for="(item, i) in dictionary.number_of_path" :key="i" :label="item.name_cyr" :value="item.id">
                                                      </el-option>
                                                    </el-select>
                                                  </el-form-item>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :md="8">
                                    <div class="transport-location">
                                        <div class="transport-location-img">
                                            <img src="../../assets/img/icons/location.svg" alt="">
                                        </div>
                                        <div class="transport-location-input">
                                            <label class="item">
                                                <p> {{$t('transport_region')}} </p>
                                                <el-form-item prop="region">
                                                    <el-select :disabled="disabled_input" v-model="form.region" class="w-full" clearable :placeholder="$t('transport_region')">
                                                        <el-option v-for="(item, i) in dictionary.region" :key="i" :label="item.name_cyr" :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </label>
                                            <label class="item">
                                                <p>{{ $t('city_or_district') }}</p>
                                                <el-form-item prop="district">
                                                    <el-select :disabled="disabled_input || !form.region" v-model="form.district" class="w-full" clearable :placeholder="$t('city_or_district')">
                                                        <el-option v-for="(item, i) in districts" :key="i" :label="item.name_cyr" :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </label>
                                            <label class="item">
                                                <p> {{$t('railway_facility_location')}} </p>
                                                <el-form-item prop="facility_location">
                                                    <el-select :disabled="disabled_input" v-model="form.facility_location" class="w-full" clearable :placeholder="$t('railway_facility_location')">
                                                        <el-option v-for="(item, i) in dictionary.facility_location" :key="i" :label="item.name_cyr" :value="item.id">
                                                        </el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </label>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                </el-collapse>
            </div>
        </div>
        <div>
            <div class="general-info">
                <div class="general-info-title">
                    {{$t('railway_facility_technical_indicators')}}
                </div>
                <hr>
                <el-collapse v-model="activeNames">
                    <el-form :model="form" :rules="formrules" ref="form2" class="Form">
                        <div class="input-section">
                            <el-row :gutter="30">
                                <el-col :md="8">
                                    <label class="item">
                                        <p> {{$t('railway_seismicity')}}</p>
                                        <el-form-item prop="seismicity">
                                          <el-select :disabled="disabled_input" v-model="form.seismicity" class="w-full" clearable :placeholder="$t('railway_seismicity')">
                                            <el-option v-for="(item, i) in dictionary.buildingseismicity" :key="i" :label="item.number" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_intermediate_device_material')}} </p>
                                        <el-form-item prop="railway_intermediate_device_material">
                                            <el-select :disabled="disabled_input" v-model="form.railway_intermediate_device_material" class="w-full" clearable :placeholder="$t('railway_intermediate_device_material')">
                                                <el-option v-for="(item, i) in dictionary.railway_intermediate_device_material" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_intermediate_device_type')}}</p>
                                        <el-form-item prop="railway_intermediate_device_type">
                                            <el-select :disabled="disabled_input" v-model="form.railway_intermediate_device_type" class="w-full" clearable :placeholder="$t('railway_intermediate_device_type')">
                                                <el-option v-for="(item, i) in dictionary.railway_intermediate_device_type" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_base_material')}} </p>
                                        <el-form-item prop="railway_base_material">
                                            <el-select :disabled="disabled_input" v-model="form.railway_base_material" class="w-full" clearable :placeholder="$t('railway_base_material')">
                                                <el-option v-for="(item, i) in dictionary.railway_base_material" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_slope_building_location')}} </p>
                                        <el-form-item prop="slope_building_location.slope_type">
                                          <el-select :disabled="disabled_input" v-model.number="form.slope_building_location.slope_type" class="w-full" clearable :placeholder="$t('railway_slope_building_location')">
                                            <el-option v-for="(item, i) in dictionary.slope_type" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                  <label class="item">
                                        <p> {{$t('railway_slope_building_location_degree')}} </p>
                                        <el-form-item prop="slope_building_location.degree">
                                          <el-input type="number" :disabled="disabled_input" v-model.number="form.slope_building_location.degree" :placeholder="`${$t('railway_slope_building_location_degree')}`" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_distance_under')}} </p>
                                        <el-form-item prop="distance_under_rail">
                                            <el-input type="number" :disabled="disabled_input" v-model.number="form.distance_under_rail" :placeholder="`${$t('railway_distance_under')}`" clearable />
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_polotno_type')}} </p>
                                        <el-form-item prop="polotno_type">
                                            <el-select :disabled="disabled_input" v-model="form.polotno_type" class="w-full" clearable :placeholder="$t('railway_polotno_type')">
                                                <el-option v-for="(item, i) in dictionary.polotno_type" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_foundation')}} </p>
                                        <el-form-item prop="foundation">
                                          <el-select :disabled="disabled_input" v-model="form.foundation" class="w-full" clearable :placeholder="$t('railway_foundation')">
                                            <el-option v-for="(item, i) in dictionary.foundation" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_reconstruction_durability')}} </p>
                                        <el-form-item prop="reconstruction_durability">
                                          <el-select :disabled="disabled_input" v-model="form.reconstruction_durability" class="w-full" clearable :placeholder="$t('railway_route')">
                                            <el-option v-for="(item, i) in dictionary.reconstructiondurability" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_route')}} </p>
                                        <el-form-item prop="railway_route">
                                            <el-select :disabled="disabled_input" v-model="form.railway_route" class="w-full" clearable :placeholder="$t('railway_route')">
                                                <el-option v-for="(item, i) in dictionary.railway_route" :key="i" :label="item.name_cyr" :value="item.id">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </label>
                                </el-col>
                                <el-col :md="8">

                                    <label class="item">
                                      <p> {{$t('railway_reconstruction_year')}} </p>
                                      <el-form-item prop="reconstruction_year">
                                        <el-date-picker :picker-options="pickerOptions"  name="construction_year" :disabled="disabled_input" :placeholder="$t('railway_reconstruction_year')" style="width: 100%" v-model="reconstruction_year_input" @change="reconstruction" type="year" value-format="yyyy">
                                        </el-date-picker>
                                      </el-form-item>
                                    </label>

                                    <label class="item">
                                      <p> {{$t('support_construction_year')}} </p>
                                      <el-form-item>
                                        <el-date-picker :picker-options="pickerOptions"  name="construction_year" :disabled="disabled_input" :placeholder="$t('support_construction_year')" style="width: 100%" v-model="support_construction_year_input" @change="support" type="year" value-format="yyyy">
                                        </el-date-picker>
                                      </el-form-item>
                                    </label>

                                    <div class="obstacle">
                                        <div class="obstacle-title">
                                            {{$t('railway_facility_dimensions')}}
                                        </div>
                                        <div class="obstacle-img-input">
                                            <div class="obstacle-img obstacle-img-railway">
                                                <img src="../../assets/img/icons/breadth.svg" alt="">
                                            </div>
                                            <div class="obstacle-input">
                                                <label class="item">
                                                    <p> {{$t('rail_distance_km')}} </p>
                                                    <el-form-item prop="rail_distance_km">
                                                        <el-input type="number" :disabled="disabled_input" v-model.number="form.rail_distance_km" :placeholder="`${$t('rail_distance_km')}`" clearable />
                                                    </el-form-item>
                                                </label>
                                                <label class="item">
                                                    <p> {{$t('rail_distance_m')}} </p>
                                                    <el-form-item prop="rail_distance_m">
                                                        <el-input type="tel" :disabled="disabled_input" v-mask="'+###################'" v-model="form.rail_distance_m" :placeholder="`${$t('rail_distance_m')}`" clearable />
                                                    </el-form-item>
                                                </label>
                                            </div>
                                        </div>
                                    </div>


                                    <label class="item">
                                        <p> {{$t('railway_strengthening_work')}} </p>
                                        <el-form-item prop="strengthening_work">
                                          <el-select :disabled="disabled_input" v-model="form.strengthening_work" class="w-full" clearable :placeholder="$t('railway_strengthening_work')">
                                            <el-option v-for="(item, i) in dictionary.strengthening_work" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_condition_of_facility')}} </p>
                                        <el-form-item prop="condition_of_facility">
                                          <el-select :disabled="disabled_input" v-model="form.condition_of_facility" class="w-full" clearable :placeholder="$t('railway_condition_of_facility')">
                                            <el-option v-for="(item, i) in dictionary.condition_of_facility" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_daily_condition')}} </p>
                                        <el-form-item prop="daily_condition">
                                          <el-select :disabled="disabled_input" v-model="form.daily_condition" class="w-full" clearable :placeholder="$t('railway_daily_condition')">
                                            <el-option v-for="(item, i) in dictionary.dailycondition" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_natural_disaster')}} </p>
                                        <el-form-item prop="natural_disaster">
                                          <el-select :disabled="disabled_input" v-model="form.natural_disaster" class="w-full" clearable :placeholder="$t('railway_natural_disaster')">
                                            <el-option v-for="(item, i) in dictionary.naturaldisasters" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_assessment_technical_condition')}} </p>
                                        <el-form-item prop="assessment_technical_condition">
                                          <el-select :disabled="disabled_input" v-model="form.assessment_technical_condition" class="w-full" clearable :placeholder="$t('railway_assessment_technical_condition')">
                                            <el-option v-for="(item, i) in dictionary.assessmenttechnicalcondition" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>
                                    <label class="item">
                                        <p> {{$t('railway_condition_of_earthquake')}} </p>
                                        <el-form-item prop="condition_of_earthquake">
                                          <el-select :disabled="disabled_input" v-model="form.condition_of_earthquake" class="w-full" clearable :placeholder="$t('railway_condition_of_earthquake')">
                                            <el-option v-for="(item, i) in dictionary.condition_of_earthquake" :key="i" :label="item.name_cyr" :value="item.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>
                                    </label>


                                </el-col>
                                <el-col :md="8">
                                    <div class="item border-t-2 pt-5">
                                        <el-form-item prop="railway_scheme_files">
                                            <FileUpload v-model="form.railway_scheme_files" format=".pdf" :name="`${$t('railway_scheme_files')}`" />
                                        </el-form-item>
                                    </div>

                                    <div v-for="(item, i) in form.intermediate_devices" :key="i">
                                      <hr>
                                      <label class="item">
                                        <p> {{$t('railway_intermediate_devices_device_m')}} </p>
                                        <el-form-item :prop="'intermediate_devices.' + i + '.device_m'" :rules="{required: true, message: 'Илтимос маълумот киритинг!', trigger: 'change'}">
                                          <el-input type="number" :disabled="disabled_input" v-model.number="item.device_m" :placeholder="`${$t('railway_intermediate_devices_device_m')}`" clearable />
                                        </el-form-item>
                                      </label>
                                      <label class="item">
                                        <p> {{$t('railway_intermediate_devices_device_num')}} </p>
                                        <el-form-item :prop="'intermediate_devices.' + i + '.device_num'" :rules="{required: true, message: 'Илтимос маълумот киритинг!', trigger: 'change'}">
                                          <el-input type="number" :disabled="disabled_input" v-model.number="item.device_num" :placeholder="`${$t('railway_intermediate_devices_device_num')}`" clearable />
                                        </el-form-item>
                                      </label>
                                      <label class="item">
                                        <p> {{$t('railway_intermediate_devices_depth_of_footing_m')}} </p>
                                        <el-form-item :prop="'intermediate_devices.' + i + '.depth_of_footing_m'" :rules="{required: true, message: 'Илтимос маълумот киритинг!', trigger: 'change'}">
                                          <el-input type="number" :disabled="disabled_input" v-model.number="item.depth_of_footing_m" :placeholder="`${$t('railway_intermediate_devices_depth_of_footing_m')}`" clearable />
                                        </el-form-item>
                                      </label>
                                    </div>

                                    <el-button @click="railway_intermediate_devices_add" style="margin: 20px 0px">
                                      {{$t('railway_intermediate_devices_add')}}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form>
                </el-collapse>
            </div>
            <div class="flex justify-end gap-2 mt-5">
                <button  class="button black" @click="$router.go(-1)">{{$t('cancel')}}</button>
                <button class="button dark-blue" @click="_Save()">{{$t('save')}}</button>
            </div>
        </div>

    </div>
    <div  v-if="modal"  class="modal fs-14" :class="modal?'active':''">
        <div class="modal-cover"></div>
        <div class="modal-item padding-map" style="width:95%; height:95%;">
            <div class="flex justify-between modal-absolute-header">
                <p class="modal-title "> {{disabled? `${$t('show_coor')}:`:`${$t('change_coor')}:`}}</p>
                <button style="font-size:16px; " @click="modal=false"><i class="el-icon-close" style="font-weight:600;"></i> </button>
            </div>
            <div class="w-full map-item h-full">
                <yandex-map @click="clicked" :coords="coords" style="height:100%; width: 100%" :zoom="14" :controls="['zoomControl']">
                    <ymap-marker :coords="coords" marker-id="123" hint-content="some hint" />
                </yandex-map>
            </div>
            <div class="flex w-full justify-between modal-bottom fw-w">
                <div class="flex items-center modal-inputes">
                    <p class="flex items-senter" style="font-size:16px;"><b class="mr-2">{{$t('coor')}}:</b> {{coords.join()}} </p>
                </div>
                <div class=" modal-inputes flex justify-center">
                    <button @click="modal=false" style="padding:15px 15px;"> <i class="el-icon-close mr-2"></i> {{$t('close')}}</button>
                    <button v-if="!disabled_input" class="ml-2" style="padding:15px 15px; background:green; " @click="SaveEditMap()"> <i class="el-icon-document-checked mr-2"></i> {{$t('save')}}</button>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import FileUpload from '@/components/FileUpload.vue'
import axios from 'axios';
export default {
    components: {
        FileUpload
    },
    computed: {
        dictionary() {
            return this.$store.state.dictionary;
        },
        role() {
          return this.$store.state.role;
        },
        me() {
          return this.$store.state.me;
        },
        regions() {
          return this.$store.state.dictionary.region;
        },
        building_group() {
          return this.$store.state.dictionary.buildinggroup;
        }


    },
    data() {
        var validateArrayLength = (rule, value, callback) => {
            console.log(value.length)
            if (rule.max >= value.length && value.length >= rule.min) {
                callback()

            } else {
                callback(new Error(`${rule.message}`))
            }
        }
        return {
            activeNames: ['1'],
            selLoading: false,
            options: [],
            cadastor_data: {},
            current_district: [],
            road_name_options:[],
            road_name:"",
            coor: '',
            coords: [41.31629324503914, 69.26717199864542],
            disabled: true,
            modal: false,
            disabled_input: false,
            reconstruction_year_input:'',
            support_construction_year_input:'',
            construction_year_input:'',

            form: {
                railway_line:'',
                region: '',
                district: '',
                rail_distance_km:'',
                rail_distance_m:'',
                facility_type:'',
                railway_route:'',
                number_of_path:'',
                transition_classification:'',
                construction_year:'',
                obstacle_name:'',
                railway_intermediate_device_material:'',
                railway_intermediate_device_type:'',
                railway_base_material:'',
                slope_building_location:{
                  slope_type:'',
                  degree:''
                },
                facility_location:'',
                polotno_type:'',
                foundation:'',
                reconstruction_year:'',
                support_construction_year:'',
                strengthening_work:'',
                condition_of_facility:'',
                condition_of_earthquake:'',
                seismicity:'',
                daily_condition:'',
                reconstruction_durability:'',
                natural_disaster:'',
                assessment_technical_condition:'',
                distance_under_rail:'',
                intermediate_devices:[
                  {
                    device_m: '',
                    device_num: '',
                    depth_of_footing_m: '',
                  }
                ],
                railway_scheme_files: [],
            },


            formrules: {
                railway_line: [{
                        required: true,
                        message: "Илтимос маълумот киритинг!",
                        trigger: "change",
                    }, ],

                region: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                district: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                rail_distance_km: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                rail_distance_m: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                facility_type: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                railway_route: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
                number_of_path: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
              transition_classification: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
              construction_year: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
              obstacle_name: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
              railway_intermediate_device_material: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
              railway_intermediate_device_type: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
              railway_base_material: [{
                    required: true,
                    message: "Илтимос маълумот киритинг!",
                    trigger: "change",
                }, ],
              slope_building_location:{
                slope_type: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
                degree: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              },
              facility_location: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              polotno_type: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              foundation: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              reconstruction_year: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              strengthening_work: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              condition_of_facility: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              condition_of_earthquake: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              seismicity: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              daily_condition: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              reconstruction_durability: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              natural_disaster: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              assessment_technical_condition: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              distance_under_rail: [{
                  required: true,
                  message: "Илтимос маълумот киритинг!",
                  trigger: "change",
                }, ],
              railway_scheme_files: [{
                validator: validateArrayLength,
                message: "1 тадан кўп ва 5 тадан кам файл юклашингиз керак!",
                min: 1,
                max: 5,
                trigger: 'change'
              }],










            },
            deniedKeys: ['railway_scheme_files'],
            arrayKeys: ['railway_scheme_files'],
            objectKeyd: ['railway_scheme_files'],
            districts: [],
            loading: false,
            pickerOptions: {
                disabledDate(date) {
                    return date > new Date('2022');
                },

            },
            railway_line_option:''
        }
    },
    watch: {
        'form.region'(val) {
            this.form.district = ''
            axios.get(`/dictionary/district/?region=${val}`)
                .then((response) => {
                    this.districts = response.data;
                })
        },
        role() {
            if (!['tf_operator', 'admin'].includes(this.role)) {
                this.$router.push("/404")
            }
        },
        'form.road_name'(val){
            this.options.forEach(element => {
                if(element.id==val){
                    this.road_name=element.name
                }
            });

            this.form.road_name_option = ''
            axios.get(`/dictionary/road_name_option/?road_name=${val}`)
                .then((response) => {
                    this.road_name_options = response.data.results;
                })
        },
    },
    methods: {
        railway_intermediate_devices_add(){
          this.form.intermediate_devices.push(
              {
                device_m: '',
                device_num: '',
                depth_of_footing_m: '',
              }
          )
        },
        railway_line(){
            if (this.form.railway_line !== '') {
                this.dictionary.railway_line.forEach(elem => {
                    if(elem.id === this.form.railway_line){
                        this.railway_line_option = elem.option
                    }
                });
            } else {
                this.railway_line_option = ''
            }
        },
        EditMap() {
            this.modal = true
            if (this.coor) {
                this.coords = this.coor.split(',')
            } else {
                this.coords = [41.31629324503914, 69.26717199864542]
            }
        },
        SaveEditMap() {
            this.modal = false
            this.coor = this.coords.join()
            this.form.geolocation = this.coords;
        },
        clicked(e) {
            if (!this.disabled_input) {
                this.coords = e.get("coords");
            }
        },
        reconstruction(){
          this.form.reconstruction_year = parseInt(this.reconstruction_year_input)
        },
        support(){
          this.form.support_construction_year = parseInt(this.support_construction_year_input)
        },
        construction(){
          this.form.construction_year = parseInt(this.construction_year_input)
        },
        _Save() {



            // console.log('this.form',this.form);

            this.$refs.form1.validate((valid1) => {
                this.$refs.form2.validate((valid2) => {
                    if (valid1 && valid2) {
                        this.loading = true
                        let data = new FormData()
                        let isArrayList = ['intermediate_devices', 'railway_scheme_files']
                        for (let obj_name of Object.keys(this.form)) {
                          if(!['slope_building_location'].includes(obj_name)) {
                            if (!isArrayList.includes(obj_name)) {
                              data.append(obj_name, this.form[obj_name])
                            } else {
                              if(['railway_scheme_files'].includes(obj_name)) {
                                for (let i = 0; i < this.form[obj_name].length; i++) {
                                  data.append(`${obj_name}[${i}]file`, this.form[obj_name][i]['file']);
                                }
                              }
                            }
                            if (['intermediate_devices'].includes(obj_name)){
                              for (let i = 0; i < this.form[obj_name].length; i++){
                                Object.keys(this.form[obj_name][i]).filter(in_key => {
                                  if (this.form.intermediate_devices[i][in_key] !== ''){
                                    data.append(`intermediate_devices[${i}]${in_key}`, this.form.intermediate_devices[i][in_key])
                                  }
                                })
                              }
                            }
                          } else {
                            Object.keys(this.form.slope_building_location).map(key => {
                              data.append(`slope_building_location.${key}`, this.form.slope_building_location[key]);
                            })
                          }
                        }

                        axios.post('/railway/', data)
                            .then(() => {
                                this.$swal.fire({
                                    icon: 'success',
                                    title: this.$t('data_saved'),
                                    timer: 3000
                                })
                                this.$router.push({
                                    path: `/transport`
                                })
                            })
                            .catch(() => {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: this.$t('error_save'),
                                    timer: 3000
                                })

                            })
                            .finally(() => {
                                this.loading = false;
                            })

                    }
                })
            })
        },
        remoteMethod(query) {
            console.log(query)
            if (query !== '') {
                this.selLoading = true
                axios.get(`/dictionary/road_name/?search=${query}`)
                    .then((response) => {
                        this.options = response.data.length > 0 ? response.data : []
                    })
                    .catch(() => {
                        this.options = []
                    })
                    .finally(() => {
                        this.selLoading = false;
                    })
            } else {
                this.options = [];
            }
        },
    },

}
</script>
<style>
.active_intermediate_devices{
  display: none;
}
</style>

<style lang="scss" scoped>

.save-cancel-btn {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 15px;
}

.cancel-btn {
    margin-right: 10px;
}

.wrapper {
    .wp-body {
        .t-title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            line-height: 37px;
            color: #004787;
        }

        .general-info {
            .general-info-title {
                font-family: 'Montserrat';
                font-style: normal;
                font-size: 18px;
                font-weight: 600;
                line-height: 37px;
                color: #004787;
                margin-top: 34px;
                margin-bottom: 15px;
            }

            hr {
                background: #004787;
                height: 2px;
            }

            .coll-title {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                color: #004787;
                margin-top: 30px;
            }

            .obstacle {
                .obstacle-img-input {
                    display: flex;
                    align-items: center;
                }

                .obstacle-title {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    color: #004787;
                    margin-left: 65px;
                    margin-top: 18px;
                }

                .obstacle-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 240px;
                    background: #D4D4D4;
                    border-radius: 6px;
                    margin-right: 10px;
                    padding: 0px 5px;
                }
                .obstacle-img-railway{
                    height: 152px;
                }

                .obstacle-input {
                    width: 100%;
                }
            }

            .transport-location {
                display: flex;
                align-items: center;

                .transport-location-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 240px;
                    background: #D4D4D4;
                    border-radius: 6px;
                    margin-right: 10px;
                    padding: 0px 5px;
                }

                .transport-location-input {
                    width: 100%;
                }
            }
        }
    }
}
</style><style lang="scss">
.item-transport {
    .el-form-item__content {
        display: flex;
        align-items: center;

        .coll-icon {
            background: #D4D4D4;
            border-radius: 6px 0px 0px 6px;
            width: 42px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        input {
            border: 1px solid #D4D4D4;
            border-radius: 0px 6px 6px 0px;
            border-left: unset;
        }
    }
}

.coll-place {
    input::placeholder {
        color: #8EACB2;
    }
}
</style>
